@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(assets/fonts/Inter.ttf) format('truetype');
}

@font-face {
  font-family: 'Nunito';
  src: local('Nunito'), url(assets/fonts/Nunito.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(assets/fonts/Raleway.ttf) format('truetype');
}

* {
    box-sizing:border-box;
    margin: 0;
    padding: 0;
}


html, body, #root {
    height: 100%;
}
